import { useCallback, useEffect, useMemo } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module";

const Setup = () => {
  // Stats
  const stats = useMemo(() => Stats(), []);
  const { style } = stats.domElement;
  style.position = "absolute";
  style.left = "auto";
  style.top = "auto";
  style.bottom = "0";
  style.right = "0";
  style.zIndex = "1000";

  // Renderer
  const renderer = useMemo(() => new THREE.WebGLRenderer(), []);

  //Camera
  const aspect = window.innerWidth / window.innerHeight;
  const camera = useMemo(
    () => new THREE.PerspectiveCamera(75, aspect, 1, 1000),
    [aspect]
  );

  // Controls
  const controls = useMemo(
    () => new OrbitControls(camera, renderer.domElement),
    [renderer, camera]
  );

  // Scene
  const scene = useMemo(() => new THREE.Scene(), []);

  // Plane
  const planeGeometry = useMemo(
    () => new THREE.PlaneGeometry(100, 100, 1, 1),
    []
  );
  const planeMaterial = useMemo(
    () =>
      new THREE.MeshLambertMaterial({
        color: 0xffffff,
      }),
    []
  );
  const plane = useMemo(
    () => new THREE.Mesh(planeGeometry, planeMaterial),
    [planeGeometry, planeMaterial]
  );

  // Light
  const ambientLight = useMemo(() => new THREE.AmbientLight(0x404040, 1), []);

  const spotLight = useMemo(() => new THREE.SpotLight(0xffffff), []);

  // Helpers
  const axesHelper = useMemo(() => new THREE.AxesHelper(100), []);

  const cameraHelper = useMemo(
    () => new THREE.CameraHelper(spotLight.shadow.camera),
    [spotLight]
  );

  const spotLightHelper = useMemo(
    () => new THREE.SpotLightHelper(spotLight),
    [spotLight]
  );

  // Basic render function
  const setupRender = useCallback(() => {
    stats.begin();
    spotLightHelper.update();
    cameraHelper.update();
    controls.update();
    renderer.render(scene, camera);
    stats.end();
  }, [stats, spotLightHelper, cameraHelper, controls, scene, camera, renderer]);

  // Settings
  useEffect(() => {
    stats.setMode(0);

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;

    camera.position.z = 10;
    //camera.position.set(-40, 40, 40);
    //camera.lookAt(scene.position);

    scene.background = new THREE.Color(0x212121);

    plane.position.set(0, -5, 0);
    plane.rotation.x = Math.PI * -0.5;
    plane.receiveShadow = true;
    scene.add(plane);

    scene.add(ambientLight);

    spotLight.position.set(25, 50, 25);
    spotLight.shadow.mapSize.width = 2048;
    spotLight.shadow.mapSize.height = 2048;
    spotLight.shadow.camera.fov = 15;
    spotLight.castShadow = true;
    spotLight.decay = 2;
    spotLight.penumbra = 0.05;
    spotLight.name = "spotLight";
    //spotLight.target = plane;
    scene.add(spotLight);

    scene.add(axesHelper);

    cameraHelper.visible = false;
    scene.add(cameraHelper);

    spotLightHelper.visible = false;
    scene.add(spotLightHelper);
  }, [
    renderer,
    scene,
    camera,
    plane,
    cameraHelper,
    ambientLight,
    spotLight,
    spotLightHelper,
    axesHelper,
    stats,
  ]);

  return {
    renderer,
    setupRender,
    camera,
    scene,
    ambientLight,
    spotLight,
    cameraHelper,
    lightHelper: spotLightHelper,
    stats,
  };
};

export default Setup;
