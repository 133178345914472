import { Vector3, Vector2 } from "three";
import { Mesh } from "./Mesh";

const ObjParser = (lines: string[]): Mesh => {
  const mesh = new Mesh();

  const vertices: Array<Vector3> = [];
  const normals: Array<Vector3> = [];
  const texCoords: Array<Vector2> = [];
  const faces: Array<number> = [];

  for (let line of lines) {
    let tokens = line.split(/\s+/);
    const rowId = tokens.shift();

    if (rowId === "f") {
      const data = tokens.map((token) => token.split("/"));

      const length = data.flat().length / 3;

      for (let i = 0; i < length; i++) {
        let indices = data.map((d) => d[i]).map(Number);
        if (indices.includes(0)) continue; //Ha '' stringet parsolt 0-ra

        switch (i) {
          case 0:
            /* indices.forEach((v, idx) =>
              indices.slice(idx + 1).forEach((w) => {
                if (!mesh.edgeIsExist(v - 1, w - 1)) {
                  mesh.edges.push(new Edge(e, v - 1, w - 1));
                  mesh.vertices[v - 1].setEdge(e);
                  mesh.vertices[w - 1].setEdge(e);
                  e++;
                }
              })
            ); */
            faces.push(...indices.map((n) => n - 1));
          /* break;
          case 1:
            mesh.indices.texture.push(...indices.map((n) => n - 1));
            break;
          case 2:
            mesh.indices.normal.push(...indices.map((n) => n - 1)); */
        }
      }
    } else {
      const data = tokens.map(parseFloat);
      switch (rowId) {
        case "v":
          vertices.push(new Vector3(data[0], data[1], data[2]));
          break;
        case "vn":
          normals.push(new Vector3(data[0], data[1], data[2]));
          break;
        case "vt":
          texCoords.push(new Vector2(data[0], data[1]));
      }
    }
  }

  mesh.builMesh(vertices, normals, faces);

  return mesh;
};

export default ObjParser;
