import { Vector3 } from "three";

export class Vertex {
  private position: Vector3;
  private id: number;
  private he: HalfEdge;
  private isNew: boolean;

  constructor(v: Vector3, idx: number) {
    this.position = v;
    this.id = idx;
  }

  getPos(): Vector3 {
    return this.position;
  }

  getEdge(): HalfEdge {
    return this.he;
  }

  getId(): number {
    return this.id;
  }

  getNew(): boolean {
    return this.isNew;
  }

  setNew(tf: boolean) {
    this.isNew = tf;
  }

  setPos(newV: Vector3) {
    this.position = newV;
  }

  setEdge(e: HalfEdge) {
    this.he = e;
  }
}

export class HalfEdge {
  private origin: Vertex;
  private sym: HalfEdge;
  private prev: HalfEdge;
  private next: HalfEdge;
  private face: Face;
  private isSplit: boolean;

  getOrigin() {
    return this.origin;
  }

  getSym() {
    return this.sym;
  }

  getPrev() {
    return this.prev;
  }

  getNext() {
    return this.next;
  }

  getFace() {
    return this.face;
  }

  getIsSplit() {
    return this.isSplit;
  }

  setIsSplit(b: boolean) {
    this.isSplit = b;
  }

  setOrigin(v: Vertex) {
    this.origin = v;
  }

  setSym(e: HalfEdge) {
    this.sym = e;
  }

  setPrev(e: HalfEdge) {
    this.prev = e;
  }

  setNext(e: HalfEdge) {
    this.next = e;
  }
  
  setFace(f: Face) {
    this.face = f;
  }
}

export class Face {
  private he: HalfEdge;

  getEdge() {
    return this.he;
  }

  setEdge(e: HalfEdge) {
    this.he = e;
  }

  getReqVertex(idx: 0 | 1 | 2): Vertex {
    switch (idx) {
      case 0:
        return this.he.getOrigin();
      case 1:
        return this.he.getNext().getOrigin();
      case 2:
        return this.he.getPrev().getOrigin();
    }
  }

  computeNormal() {
    const v0 = this.getReqVertex(0).getPos();
    const v1 = this.getReqVertex(1).getPos();
    const v2 = this.getReqVertex(2).getPos();

    const u = new Vector3().subVectors(v1, v0).normalize();
    const v = new Vector3().subVectors(v2, v0).normalize();
    const w = u.cross(v).normalize();

    return w;
  }
}

export class Mesh {
  private vertices: Array<Vertex>;
  private edges: Array<HalfEdge>;
  private faces: Array<Face>;
  private normals: Vector3[];
  private edgeMap: Map<string, HalfEdge>;

  constructor() {
    this.vertices = [];
    this.edges = [];
    this.faces = [];
    this.normals = [];
    this.edgeMap = new Map();
  }

  clear() {
    this.vertices = [];
    this.edges = [];
    this.faces = [];
    this.normals = [];
    this.edgeMap.clear();
  }

  builMesh(
    vertices: Array<Vector3>,
    normals: Array<Vector3>,
    faces: Array<number>
  ) {
    this.clear();

    // csúcs adatok hozzáadása
    vertices.forEach((vertex, idx) => this.addVertexPos(vertex, idx));
    normals.forEach((normal) => this.normals.push(normal));

    // Háromszögek kialakítása
    for (let i = 0; i < faces.length; i += 3) {
      let v0 = this.vertices[faces[i]];
      let v1 = this.vertices[faces[i + 1]];
      let v2 = this.vertices[faces[i + 2]];
      this.addFaceByVertices(v0, v1, v2);
    }

    this.edgeMap.clear();

    if (!normals.length) {
      this.computeNormal();
    }
  }

  copyFrom(m: Mesh): Mesh {
    // Csúcspontok másolása
    m.getVertices().forEach((v, i) => this.addVertexPos(v.getPos(), i));

    // Lapok másolása
    m.getFaces().forEach((f) => {
      let v0 = this.vertices[f.getReqVertex(0).getId()];
      let v1 = this.vertices[f.getReqVertex(1).getId()];
      let v2 = this.vertices[f.getReqVertex(2).getId()];
      this.addFaceByVertices(v0, v1, v2);
    });

    this.edgeMap.clear();

    return this;
  }

  addVertexPos(v: Vector3, i: number): Vertex {
    this.vertices.push(new Vertex(new Vector3().copy(v), i));
    return this.vertices[this.vertices.length - 1];
  }

  addFaceByVertices(v1: Vertex, v2: Vertex, v3: Vertex) {
    let he1 = this.findEdge(v1, v2),
      he2 = this.findEdge(v2, v3),
      he3 = this.findEdge(v3, v1);

    if (!he1) {
      he1 = this.addEdge(v1, v2);
    }
    if (!he2) {
      he2 = this.addEdge(v2, v3);
    }
    if (!he3) {
      he3 = this.addEdge(v3, v1);
    }

    return this.addFaceByHE(he1, he2, he3);
  }

  addFaceByHE(e1: HalfEdge, e2: HalfEdge, e3: HalfEdge) {
    const f = new Face();

    // A lap élhez kapcsolása
    f.setEdge(e1);

    // Az élek laphoz kapcsolása
    e1.setFace(f);
    e2.setFace(f);
    e3.setFace(f);

    // Az élek összekapcsolása körbe a lapon
    e1.setNext(e2);
    e2.setPrev(e1);
    e2.setNext(e3);
    e3.setPrev(e2);
    e3.setNext(e1);
    e1.setPrev(e3);

    this.faces.push(f);

    return f;
  }

  addFace(): Face {
    this.faces.push(new Face());
    return this.faces[this.faces.length - 1];
  }

  addHalfEdge(): HalfEdge {
    this.edges.push(new HalfEdge());
    return this.edges[this.edges.length - 1];
  }

  addEdge(v1: Vertex, v2: Vertex): HalfEdge {
    const he = new HalfEdge();

    const key = `${String(v1.getId())},${String(v2.getId())}`;
    this.edgeMap.set(key, he);

    // Az él kezdő csúcsa
    he.setOrigin(v1);
    if (!v1.getEdge()) {
      v1.setEdge(he);
    }

    // Az él és a szomszédjának összerendelése (ha létezik)
    const heSym = this.findEdge(v2, v1);
    if (!!heSym) {
      he.setSym(heSym);
      heSym.setSym(he);
    }

    this.edges.push(he);

    return he;
  }

  findEdge(v1: Vertex, v2: Vertex): HalfEdge | undefined {
    let currentKey = `${String(v1.getId())},${String(v2.getId())}`;
    return this.edgeMap.get(currentKey);
  }

  computeNormal() {
    this.normals = [];
    let count: Array<number> = [];

    this.getVertices().forEach(() => {
      this.normals.push(new Vector3(0.0, 0.0, 0.0));
      count.push(0);
    });

    this.getFaces().forEach((f, i) => {
      let n = f.computeNormal();

      let idx0 = f.getEdge().getOrigin().getId();
      let idx1 = f.getEdge().getNext().getOrigin().getId();
      let idx2 = f.getEdge().getPrev().getOrigin().getId();

      this.normals[idx0].add(n);
      this.normals[idx1].add(n);
      this.normals[idx2].add(n);

      count[idx0] += 1;
      count[idx1] += 2;
      count[idx2] += 3;
    });

    this.getNormals().forEach((n, i) => {
      n.multiplyScalar(count[i]);
      n.normalize();
    });
  }

  getVertices(): Array<Vertex> {
    return this.vertices;
  }

  getNumOfVertices(): number {
    return this.vertices.length;
  }

  getEdges(): Array<HalfEdge> {
    return this.edges;
  }

  getnumOfEdges(): number {
    return this.edges.length;
  }

  getFaces(): Array<Face> {
    return this.faces;
  }

  getNumOfFaces(): number {
    return this.faces.length;
  }

  getNormals(): Array<Vector3> {
    return this.normals;
  }

  getEdgeMap(): Map<string, HalfEdge> {
    return this.edgeMap;
  }

  addToEdgeMap(key: string, he: HalfEdge): Map<string, HalfEdge> {
    return this.edgeMap.set(key, he);
  }

  getDrawVertices(): Array<number> {
    return this.vertices
      .map((vertex) => [
        vertex.getPos().x,
        vertex.getPos().y,
        vertex.getPos().z,
      ])
      .flat();
  }

  getDrawIndices(): Array<number> {
    return this.faces
      .map((f) => [
        f.getEdge().getOrigin().getId(),
        f.getEdge().getNext().getOrigin().getId(),
        f.getEdge().getPrev().getOrigin().getId(),
      ])
      .flat();
  }
}
