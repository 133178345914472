import { useState } from "react";
import { Box, Stack } from "@chakra-ui/layout";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Progress,
} from "@chakra-ui/react";
import ObjParser from "../../helpers/ObjParser";
import ObjFileReader from "../../helpers/ObjFileReader";
import { Mesh } from "../../helpers/Mesh";

interface Props {
  buttonText: string;
  helperText: string;
  setModel: (m: Mesh) => void;
}

const FileSelector = ({ helperText, buttonText, setModel }: Props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [progress, setProgress] = useState(false);

  const fileFormatChecker = (str: string): boolean => {
    const subStr = str.split(".");
    if (subStr[subStr.length - 1] === "obj") {
      setErrorMsg("");
      return true;
    } else {
      setErrorMsg("Rossz formátum!");
      return false;
    }
  };

  const onChangeHandler = (file: File) => {
    if (!fileFormatChecker(file.name)) return;
    setProgress(true);
    ObjFileReader(file)
      .then((raw) => ObjParser(raw))
      .then((data) => setModel(data))
      .catch((error) => console.log(error))
      .finally(() => setTimeout(() => setProgress(false), 500));
  };

  return (
    <Stack spacing={2}>
      <FormControl id="file" isInvalid={errorMsg.length !== 0}>
        <FormLabel m="0" color="white">
          <Box
            p={2}
            w="100%"
            h="40px"
            border="1px"
            borderColor="whiteAlpha.300"
            borderRadius="md"
            d="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            transition="all .3s ease-in-out"
            _hover={{ background: "whiteAlpha.300" }}
          >
            {buttonText}
          </Box>
        </FormLabel>
        <Input
          type="file"
          display="none"
          onChange={({ target: { files } }) => {
            if (files && files.length !== 0) onChangeHandler(files[0]);
          }}
        />
        <FormHelperText color="white">{helperText}</FormHelperText>
        <FormErrorMessage color="white">{errorMsg}</FormErrorMessage>
      </FormControl>
      <Progress colorScheme="green" size="xs" isIndeterminate={progress} />
    </Stack>
  );
};

export default FileSelector;
