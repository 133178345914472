import { Vector3 } from "three";
import { Face, HalfEdge, Mesh, Vertex } from "./Mesh";

export const butterfly = (m: Mesh): Mesh => {
  const newMesh = new Mesh().copyFrom(m);

  preparateToSubdivision(newMesh);

  newMesh.getEdges().forEach((e) => !e.getIsSplit() && splitEdge(e, newMesh));

  newMesh
    .getVertices()
    .forEach((v) => v.getNew() && v.setPos(getNewButterflyCoord(v)));

  newMesh.getFaces().forEach((f) => {
    Array.from({ length: 3 }).forEach(() => refreshFace(f, newMesh));
  });

  newMesh.computeNormal();

  return newMesh;
};

// Az új csúcspontok kiszámítása és frissítése
const getNewButterflyCoord = (v: Vertex): Vector3 => {
  // Szomszédok
  const he = v.getEdge();
  const right = he.getNext();
  const top = right.getNext().getNext();
  const left = top.getNext().getNext();
  const topLeft = left.getPrev().getSym().getPrev().getPrev();
  const topRight = right.getSym().getNext().getNext().getNext();
  const bottom = he.getSym().getPrev().getPrev();
  const bottomRight = bottom.getSym().getNext().getNext().getNext();
  const bottomLeft = bottom.getPrev().getSym().getPrev().getPrev();

  return new Vector3()
    .copy(
      new Vector3()
        .copy(left.getOrigin().getPos())
        .add(new Vector3().copy(right.getOrigin().getPos()))
        .multiplyScalar(1 / 2)
    )
    .add(
      new Vector3()
        .copy(top.getOrigin().getPos())
        .add(new Vector3().copy(bottom.getOrigin().getPos()))
        .multiplyScalar(1 / 8)
    )
    .add(
      new Vector3()
        .copy(topLeft.getOrigin().getPos())
        .add(new Vector3().copy(topRight.getOrigin().getPos()))
        .multiplyScalar(-1 / 16)
    )
    .add(
      new Vector3()
        .copy(bottomLeft.getOrigin().getPos())
        .add(new Vector3().copy(bottomRight.getOrigin().getPos()))
        .multiplyScalar(-1 / 16)
    );
};

export const loop = (m: Mesh): Mesh => {
  const newMesh = new Mesh().copyFrom(m);

  preparateToSubdivision(newMesh);

  newMesh.getEdges().forEach((e) => !e.getIsSplit() && splitEdge(e, newMesh));

  newMesh.getVertices().forEach((v) => v.setPos(getNewLoopCoord(v, newMesh)));

  newMesh.getFaces().forEach((f) => {
    Array.from({ length: 3 }).forEach(() => refreshFace(f, newMesh));
  });

  newMesh.computeNormal();

  return newMesh;
};

const getNewLoopCoord = (v: Vertex, m: Mesh): Vector3 => {
  return new Vector3().copy(v.getPos());
};

const preparateToSubdivision = (M: Mesh): Mesh => {
  M.getVertices().forEach((vertex) => vertex.setNew(false));
  M.getEdges().forEach((edge) => edge.setIsSplit(false));
  return M;
};

// Új csúcs hozzáadása a mesh-hez
const generateNewVertex = (he: HalfEdge, M: Mesh): Vertex => {
  const origin = he.getOrigin().getPos();
  const next = he.getSym().getOrigin().getPos();
  he.setIsSplit(true);
  he.getSym().setIsSplit(true);

  const newPos = new Vector3()
    .copy(origin)
    .add(next)
    .multiplyScalar(1 / 2);

  const index = M.getVertices().length;
  const newVertex = M.addVertexPos(newPos, index);
  newVertex.setNew(true);
  newVertex.setEdge(he);

  return newVertex;
};

const refreshFace = (f: Face, M: Mesh): Mesh => {
  let he = f.getEdge();

  // Meg kell győződni róla, hogy ez egy újonnan létrehozott csúcs e
  while (he.getOrigin().getNew() !== true) {
    he = he.getNext();
  }

  // Az új (fél) él csúcsai
  const v1 = he.getNext().getNext().getOrigin();
  const v2 = he.getOrigin();

  // Új (fél) él hozzáadása
  const newhe = M.addEdge(v1, v2);
  const newheSym = M.addEdge(v2, v1);

  // A (fél) él "isSplit" propertyjének beállítása
  newhe.setIsSplit(true);
  newheSym.setIsSplit(true);

  // Az új (fél) él és a párjának az adatainak a beállítása
  newheSym.setPrev(he.getPrev());
  newheSym.setNext(he.getNext().getNext());
  newheSym.getPrev().setNext(newheSym);
  newheSym.getNext().setPrev(newheSym);

  newhe.setNext(he);
  newhe.setPrev(he.getNext());
  newhe.getPrev().setNext(he);
  newhe.getNext().setPrev(he);

  // A lap élének beállítása a következő iterációhoz
  f.setEdge(newheSym.getNext());

  // A párjának a lapjának beállítása a régi lapra
  newheSym.setFace(f);

  // Az új lap hozzáadása a mesh-hez
  M.addFaceByHE(newhe, he, newhe.getPrev());

  return M;
};

const splitEdge = (he: HalfEdge, M: Mesh): Mesh => {
  const newVertex = generateNewVertex(he, M);
  const face = he.getFace();
  const symFace = he.getSym().getFace();
  const sym = he.getSym();
  const prev = he.getPrev();
  const symNext = he.getSym().getNext();

  const v1 = he.getOrigin();

  // Új (fél) él előállítása
  const newHe = M.addEdge(v1, newVertex);
  const newHeSym = M.addEdge(newVertex, v1);

  // Az új (fél) él lapjának és szomszédjának a beállítása
  newHe.setFace(face);
  newHeSym.setFace(symFace);

  // A kezdő csúcsa, előző és következő beállítása a (fél) élnél
  newHe.setPrev(prev);
  newHe.setNext(he);
  newHe.getPrev().setNext(newHe);
  newHe.getNext().setPrev(newHe);
  newHe.getOrigin().setEdge(newHe);

  // A kezdő csúcsa, előző és következő beállítása a (fél) él párjánál
  newHeSym.setPrev(sym);
  newHeSym.setNext(symNext);
  newHeSym.getNext().setPrev(newHeSym);
  newHeSym.getPrev().setNext(newHeSym);

  // A régi (fél) él kiinduló csúcsának megváltoztatása
  he.setOrigin(newVertex);
  he.setPrev(newHe);
  he.getNext().setPrev(he);
  he.getPrev().setNext(he);

  // A (fél) él szomszédjának következő és előzőjének beállítása
  he.getSym().setNext(newHeSym);
  he.getSym().getNext().setPrev(he.getSym());
  he.getSym().getPrev().setNext(he.getSym());

  he.setPrev(newHe);
  he.getPrev().setSym(newHeSym);

  // Az élek "isSplit" állapotának a frissítése
  he.getPrev().setIsSplit(true);
  he.getPrev().getSym().setIsSplit(true);
  newHe.setIsSplit(true);
  newHeSym.setIsSplit(true);

  // Az edgesMap frissítése az új kulcs és él párokkal
  let key = `${String(he.getOrigin().getId())},${String(
    he.getSym().getOrigin().getId()
  )}`;
  M.addToEdgeMap(key, he);

  key = `${String(he.getSym().getOrigin().getId())},${String(
    he.getOrigin().getId()
  )}`;
  M.addToEdgeMap(key, he.getSym());

  newVertex.setEdge(he);

  return M;
};
