const ObjFileReader = (file: File): Promise<string[]> => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    const lines: string[] = [];

    reader.onload = async (event: ProgressEvent<FileReader>) => {
      const text = event.target?.result?.toString() || "";
      lines.push(...text.split(/\r\n|\n/));
    };

    reader.onloadend = () => resolve(lines);

    reader.onerror = (event) => {
      console.log(event.target?.error);
      reject(event.target?.error);
    };

    reader.readAsText(file);
  });
};

export default ObjFileReader;
