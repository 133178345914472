import { Stack } from "@chakra-ui/layout";
import {
  Text,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Box,
  Divider,
  Select,
} from "@chakra-ui/react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import objList from "../../data/objList";

import FileSelector from "../FileSelector/FileSelector";
import { Mesh } from "../../helpers/Mesh";

import "./Menu.css";
import ObjParser from "../../helpers/ObjParser";

interface Props {
  className?: string;
  modelChangeHandler: (m: Mesh) => void;
  loopSubdivisionHandler: () => void;
  butterflySubdivisionHandler: () => void;
  iterationInfo: Array<{
    numOfVertices: number;
    numOfFaces: number;
    numOfEdges: number;
  }>;
  idOfCheckout: number;
  checkoutHandler: (n: number) => void;
}

const Menu = ({
  modelChangeHandler,
  className,
  loopSubdivisionHandler,
  butterflySubdivisionHandler,
  iterationInfo,
  idOfCheckout,
  checkoutHandler,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectModelHandler = (name: string) => {
    if (!name) return;
    const raw = require(`../../assets/${name}`);
    fetch(raw.default)
      .then((res) => res.text())
      .then((text) => ObjParser(text.split(/\r\n|\n/)))
      .then((data) => modelChangeHandler(data))
      .catch((error) => console.log(error));
  };

  return (
    <div className={className}>
      <Button
        colorScheme="whiteAlpha"
        onClick={onOpen}
        position="absolute"
        top="4"
        left="4"
        borderRadius="full"
        color="white"
      >
        <FontAwesomeIcon icon={faEdit} color="white" />
      </Button>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent background="#212121">
          <DrawerCloseButton colorScheme="whiteAlpha" color="white" />
          <DrawerHeader color="white">Beállítások</DrawerHeader>
          <DrawerBody>
            <Stack spacing={6}>
              <Text fontSize="lg" color="white">
                Model
              </Text>
              <Select
                placeholder="Kiválasztás"
                onChange={({ target: { value } }) => selectModelHandler(value)}
                color="white"
              >
                {objList.map((obj, index) => (
                  <option value={obj.path} key={index} color="white">
                    {obj.name}
                  </option>
                ))}
              </Select>
              <Stack direction="row" alignItems="center">
                <Divider />
                <Text color="white">Vagy</Text>
                <Divider />
              </Stack>
              <FileSelector
                buttonText="Tallózás"
                helperText="Válaszd ki a .obj modelt!"
                setModel={modelChangeHandler}
              />
              <Stack spacing={6}>
                <Button
                  variant="outline"
                  onClick={butterflySubdivisionHandler}
                  color="white"
                >
                  Butterfly Subdivision
                </Button>
                <Button
                  variant="outline"
                  onClick={loopSubdivisionHandler}
                  disabled={true}
                  color="white"
                >
                  Loop Subdivision
                </Button>
              </Stack>
              {iterationInfo.length > 0 && (
                <Stack mt={6} pb={16} spacing={0} minHeight="80px">
                  {iterationInfo.map((info, i) => (
                    <Stack
                      direction="column"
                      alignItems="center"
                      spacing="0"
                      key={i}
                    >
                      {i > 0 && (
                        <Divider
                          orientation="vertical"
                          h="50px"
                          borderWidth="2px"
                          borderColor="whiteAlpha.600"
                        />
                      )}
                      <Box
                        position="relative"
                        w="100%"
                        d="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          bg={
                            idOfCheckout === i
                              ? "whiteAlpha.800"
                              : "whiteAlpha.100"
                          }
                          color={idOfCheckout === i ? "black" : "white"}
                          w="30px"
                          p="10px"
                          h="30px"
                          d="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="full"
                          userSelect="none"
                          cursor="pointer"
                          transition="all .3s ease-in-out"
                          _hover={{
                            bg: "blackAlpha.500",
                            color: "white",
                          }}
                          onClick={() => checkoutHandler(i)}
                        >
                          {`${i}.`}
                        </Box>
                        <Box
                          position="absolute"
                          top="0"
                          left="50%"
                          right="30px"
                          transform={
                            i % 2 === 0
                              ? "translateX(30px)"
                              : "translateX(calc(-100% - 30px))"
                          }
                        >
                          <Stack
                            bg="whiteAlpha.100"
                            spacing={0}
                            borderRadius={
                              i % 2 === 0
                                ? "10px 15px 15px 15px"
                                : "15px 10px 15px 15px"
                            }
                            paddingBlock="1"
                            paddingInline="2"
                            _before={{
                              content: '""',
                              width: 0,
                              height: 0,
                              borderTop: "7.5px solid transparent",
                              borderBottom: "7.5px solid transparent",
                              ...(i % 2 === 0
                                ? {
                                    borderRight:
                                      "7.5px solid rgba(255,255,255,0.06)",
                                  }
                                : {
                                    borderLeft:
                                      "7.5px solid rgba(255,255,255,0.06)",
                                  }),
                              position: "absolute",
                              top: "7.5px",
                              left: i % 2 === 0 ? "-7.5px" : "100%",
                            }}
                          >
                            <Text color="white">
                              Csúcs: {info.numOfVertices}
                            </Text>
                            <Text color="white">Lap: {info.numOfFaces}</Text>
                            <Text color="white">Él: {info.numOfEdges}</Text>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Text color="whiteAlpha.400" userSelect="none">
              created by Rogoz György
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default Menu;
