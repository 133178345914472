import { useState } from "react";

import Renderer from "../Renderer/Renderer";
import Menu from "../Menu/Menu";
import "./Layout.css";
import { Mesh } from "../../helpers/Mesh";
import { butterfly, loop } from "../../helpers/Subdivision";

interface Props {}

const Layout = (props: Props) => {
  const [models, setModels] = useState<Array<Mesh>>([]);
  const [selectedIteration, setSelectedIteration] = useState<number>(0);

  const modelLoadHandler = async (m: Mesh) => {
    setSelectedIteration(0);
    setModels([m]);
  };

  const loopSubdivisionHandler = async () => {
    if (!!models.length) {
      const sub = loop(models[models.length - 1]);
      setModels([...models, sub]);
      setSelectedIteration(models.length);
    }
  };

  const butterflySubdivisionHandler = () => {
    if (!!models.length) {
      const sub = butterfly(models[models.length - 1]);
      setModels([...models, sub]);
      setSelectedIteration(models.length);
    }
  };

  const checkoutHandler = (n: number) => {
    setSelectedIteration(n);
  };

  return (
    <>
      {!!models.length && (
        <Renderer className="Canvas" model={models[selectedIteration]} />
      )}
      <Menu
        className="Settings"
        modelChangeHandler={modelLoadHandler}
        loopSubdivisionHandler={loopSubdivisionHandler}
        butterflySubdivisionHandler={butterflySubdivisionHandler}
        iterationInfo={models.map((m) => ({
          numOfVertices: m.getNumOfVertices(),
          numOfFaces: m.getNumOfFaces(),
          numOfEdges: m.getnumOfEdges() / 2,
        }))}
        idOfCheckout={selectedIteration}
        checkoutHandler={checkoutHandler}
      />
    </>
  );
};

export default Layout;
