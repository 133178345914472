const objList = [
  { name: "Banana", path: "banana.obj" },
  { name: "Bunny", path: "bunny.obj" },
  { name: "Cow", path: "cow.obj" },
  { name: "Cube", path: "cube.obj" },
  { name: "Donut", path: "donut.obj" },
  { name: "Frame", path: "frame.obj" },
  { name: "Humanoid", path: "humanoid_tri.obj" },
  { name: "Icosahedron", path: "ico.obj" },
  { name: "Mechpart", path: "mechpart.obj" },
  { name: "Octahedron", path: "octahedron.obj" },
  { name: "Octus", path: "octus.obj" },
  { name: "Pyramis", path: "piramis.obj" },
  { name: "Stell", path: "stell.obj" },
  { name: "T", path: "t.obj" },
  { name: "Tetra", path: "tetra.obj" },
  { name: "Tet", path: "tet.obj" },
  { name: "Teteahedron", path: "tetrahedron.obj" },
  { name: "Torus", path: "torus.obj" },
  { name: "Venus", path: "venus.obj" },
];

export default objList;