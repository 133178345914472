import { useRef, useLayoutEffect, useEffect, useMemo } from "react";
import Setup from "./Setup";
import * as THREE from "three";
import { GUI } from "three/examples/jsm/libs/dat.gui.module";
import { Mesh } from "../../helpers/Mesh";
import "./Renderer.css";

interface Props {
  className: string;
  model: Mesh;
}

const Test2 = ({ model, className }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    camera,
    renderer,
    setupRender,
    scene,
    stats,
    ambientLight,
    spotLight,
    cameraHelper,
    lightHelper,
  } = Setup();

  // Object's group
  const objectGroup = useMemo(() => new THREE.Group(), []);

  const material = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        color: 0x29876a,
        flatShading: true,
        visible: true,
        depthTest: true,
      }),
    []
  );

  const lines = useMemo(
    () =>
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
        visible: false,
        wireframe: true,
      }),
    []
  );

  // Dat.gui
  const guiParams = useMemo(
    () => ({
      rotationX: 0.001,
      rotationY: 0.001,
      base: {
        color: material.color.getStyle(),
        visible: material.visible.valueOf(),
      },
      wire: {
        color: lines.color.getStyle(),
        visible: lines.visible.valueOf(),
      },
      stopButton: function () {
        this.rotationX = 0;
        this.rotationY = 0;
      },
      startButton: function () {
        this.rotationX = 0.001;
        this.rotationY = 0.001;
      },
      cameraHelper: false,
      ambientColor: ambientLight.color.getStyle(),
      pointColor: spotLight.color.getStyle(),
      intensity: 1,
      distance: 0,
      angle: 0.1,
      lightHelper: false,
      penumbra: 0,
      castShadow: true,
    }),
    [material, lines, spotLight, ambientLight]
  );

  useEffect(() => {
    const gui = new GUI();
    const rotationFolder = gui.addFolder("Forgatás");
    rotationFolder
      .add(guiParams, "rotationX", -0.02, 0.02)
      .step(0.001)
      .listen();
    rotationFolder
      .add(guiParams, "rotationY", -0.02, 0.02)
      .step(0.001)
      .listen();
    rotationFolder.open();

    const cameraFolder = gui.addFolder("Kamera");
    cameraFolder.add(camera.position, "z", 0, 10);
    cameraFolder
      .add(guiParams, "cameraHelper")
      .onChange((b: boolean) => (cameraHelper.visible = b));
    cameraFolder.open();

    const baseFolder = gui.addFolder("Alap");
    baseFolder
      .addColor(guiParams.base, "color")
      .onChange(
        (c: THREE.ColorRepresentation) => (material.color = new THREE.Color(c))
      );
    baseFolder
      .add(guiParams.base, "visible")
      .onChange((b: boolean) => (material.visible = b));
    baseFolder.open();

    const wireFolder = gui.addFolder("Háló");
    wireFolder
      .addColor(guiParams.wire, "color")
      .onChange(
        (c: THREE.ColorRepresentation) => (lines.color = new THREE.Color(c))
      );
    wireFolder
      .add(guiParams.wire, "visible")
      .onChange((b: boolean) => (lines.visible = b));
    wireFolder.open();

    const actionFolder = gui.addFolder("Műveletek");
    actionFolder.add(guiParams, "stopButton").name("Stop");
    actionFolder.add(guiParams, "startButton").name("Start");

    const lightFolder = gui.addFolder("Megvilágítás");
    lightFolder
      .addColor(guiParams, "ambientColor")
      .onChange(
        (c: THREE.ColorRepresentation) =>
          (ambientLight.color = new THREE.Color(c))
      );
    lightFolder
      .addColor(guiParams, "pointColor")
      .onChange(
        (c: THREE.ColorRepresentation) => (spotLight.color = new THREE.Color(c))
      );
    lightFolder
      .add(guiParams, "intensity", 0, 5, 0.1)
      .onChange((e) => (spotLight.intensity = e));
    lightFolder
      .add(guiParams, "angle", 0, Math.PI * 2)
      .onChange((e) => (spotLight.angle = e));
    lightFolder
      .add(guiParams, "penumbra", 0, 1)
      .onChange((e) => (spotLight.penumbra = e));
    lightFolder
      .add(guiParams, "distance", 0, 100)
      .onChange((e) => (spotLight.distance = e));
    lightFolder
      .add(guiParams, "lightHelper")
      .onChange((b: boolean) => (lightHelper.visible = b));
    lightFolder
      .add(guiParams, "castShadow")
      .onChange((e) => (spotLight.castShadow = e));

    gui.close();

    return () => {
      gui.destroy();
    };
  }, [
    guiParams,
    spotLight,
    ambientLight,
    lightHelper,
    camera,
    cameraHelper,
    lines,
    material,
  ]);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      current.appendChild(renderer.domElement);
      current.appendChild(stats.domElement);
    }

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", onWindowResize, false);

    return () => {
      if (current) {
        current.removeChild(renderer.domElement);
        current.removeChild(stats.domElement);
      }
      window.removeEventListener("resize", onWindowResize);
    };
  }, [camera, renderer, stats]);

  useEffect(() => {
    objectGroup.clear();

    const geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array(model.getDrawVertices());
    geometry.setIndex(model.getDrawIndices());
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
    geometry.computeVertexNormals();

    const mesh = new THREE.Mesh(geometry, material);
    mesh.castShadow = true;

    const wireframe = new THREE.Mesh(geometry, lines);

    objectGroup.add(mesh, wireframe);
    scene.add(objectGroup);

    return () => {
      objectGroup.remove(mesh);
    };
  }, [model, lines, material, objectGroup, scene]);

  renderer.setAnimationLoop(() => {
    setupRender();
    objectGroup.rotation.x += guiParams.rotationX;
    objectGroup.rotation.y += guiParams.rotationY;
  });

  return <div className={className} ref={ref} />;
};

export default Test2;
